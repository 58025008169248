@import url('https://fonts.cdnfonts.com/css/leelawadee');

.wrapper * {
  font-family: "Leelawadee";
}

.wrapper {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 20px;
}

.wrapper > span {
  font-size: 20px;
  color: rgb(128, 128, 128);
}

.wrapper .links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper .links {
  width: max-content;
}

.wrapper .links .btn {
  font-size: 16px; 
  padding: 10px 20px;   
  border: 1px solid black;
  text-decoration: none;  
}

.wrapper .links .btn:first-of-type {
  background-color: black;
  color: white;
}

.wrapper .links .btn:first-of-type:hover {
  background-color: white;
  color: black;  
}

.wrapper .links .btn:last-of-type {
  background-color: white;
  color: black;
}


.wrapper .links .btn:last-of-type:hover {
  background-color: black;
  color: white;
}

@media (min-width: 450px) {
  .wrapper .links {
    flex-direction: row;
    gap: 30px;
    width: 100%;
    max-width: 400px;
  }
  .wrapper .links .btn {    
    flex: 1; /* Added */
  }
}
