@keyframes shimmerAnimation {
  0% {
    background-position: -2000px 0;
  }
  25% {
    background-position: -1000px 0;
  }
  50% {
    background-position: 1000px 0;
  }
  75% {
    background-position: 2000px 0;
  }
  100% {
    background-position: -2000px 0;
  }
}

.placeholder {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* background-blend-mode: multiply;
  filter: brightness(60%); */
}

.placeholder::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #dedede 25%,
    #bcbcbc 50%,
    #cccccc 75%,
    #ffffff 100%
  ); 
  animation: shimmerAnimation 15s infinite;
}

@media (min-width: 1000px) {
  .placeholder::before {
    animation: shimmerAnimation 10s infinite;
  }
}

@media (min-width: 1500px) {
  .placeholder::before {
    animation: shimmerAnimation 5s infinite;
  }
}