@import url('https://fonts.cdnfonts.com/css/leelawadee');

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  max-width: 1700px;
  margin: 0 auto;
}

.card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 0px;
  border: none;
}

.card img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  border-radius: 0px;
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover .cardOverlay {
  opacity: 1;
}

.cardOverlay h2 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  font-family: "Leelawadee";
}

.skeleton {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #f6f6f6;
  overflow: hidden;
  border: 10px solid rebeccapurple;
}

.image {
  width: 100%;
  height: 100%;
  background-color: #e8e8e8;
  animation: shimmer 1s infinite;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.rendered {  
  visibility: visible;
}
.loading {
  visibility: hidden;
}
.ring {
  /* margin-top: auto;
  margin-left: auto;
  margin-right: auto; */
  position: absolute;
  z-index: 100;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

}

.centered-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  z-index: 200;
  /* background-color: red; */
  height: 300px;
  width: 300px;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

@media (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}
