@import url('https://fonts.cdnfonts.com/css/leelawadee');


.projects * {  
  font-family: 'Leelawadee';
}


.projects {
  margin-top: 100px;
  text-align: center;
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}
.projects h1 {
  font-size: 25px;
  color: rgb(128, 128, 128);
}

.projectWrapper {
  margin: auto;  
  justify-content: center;  
}

.link {
  margin-top: 50px;
  text-decoration: none;
  color: rgb(60, 60, 60);
  display: inline-block;
  font-size: 20px;
  text-decoration: underline;
}
.link:hover {
  text-decoration: none;
  color: rgb(60, 60, 60);
}

.card {
  height: 350px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0;  
  margin-top: 40px;
  padding: 0;
  width: 500px;
  border-radius: 0px;
}
.card * {
  border-radius: 0px;
}

.cardInner {
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.card:hover .cardOverlay {
  opacity: 1;
}

.cardOverlay span {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 650px) {
  .projects h1 {
    font-size: 30px;
  }
  .projectWrapper {
    /* justify-content: space-between; */
    column-gap: 40px;
    max-width: 1120px;
    /* margin-top: 50px; */
    /* margin: auto; */
  }
}
@media (min-width: 1675px) {
  .projectWrapper {
    justify-content: space-between;
    max-width: none;
    column-gap: 0px;
  }
  .card {
    width: 525px;
  }

}
