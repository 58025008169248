@import url('https://fonts.googleapis.com/css2?family=Carlito&display=swap');
@import url('https://fonts.cdnfonts.com/css/leelawadee');



.carousel * {
  font-family: "Leelawadee";
}
.carousel h1 {
  font-family: "Lucida Sans";
}



.carousel {
  display: flex;  
  /* background-color: peru;   */
  position: relative;  
}

.slide {
  width: 95vw;
  margin-left: auto;
  height: 60vh;
  flex-grow: 1;
  display: flex;
}

.slide img {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-blend-mode: multiply;
  filter: brightness(60%);
}


/* Rest of your CSS rules */


/* Rest of your CSS rules */


.leftArrow {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.rightArrow {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.customArrow {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
  z-index: 2;
}

.textOverlay {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  height: 50%;
  width: 80%;
  /* background-color: peru;   */
  display: flex;
  flex-direction: column;
}
.textOverlay h1 {
  font-size: 40px;
  color: white;
}
.textOverlay p {
  color: white;
  padding-top: 20px;
  font-size: 20px;
}
.textOverlay p br {
  display: none;
}

.links {
  /* max-width: 200px; */
  /* border: 1px solid red; */
  /* flex-direction: column; */
  width: 100%;  
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}


.textOverlay a {
  /* display: inline-flex;   */
  /* background-color: red; */
  display: flex;  
  /* margin-top: 20px;   */
  cursor: pointer; 
  text-decoration: none;  
  /* border: 1px solid red; */
}
.textOverlay button {
  width: 200px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-weight: 600;
  padding: 5px;
  margin: auto;  
  transition: border-color 0.5s ease-in-out; 
}
.textOverlay button:hover {
  border-color: transparent;  
}

@media (min-width: 450px) {
  .textOverlay {
    display: block;
    top: 50%;
  }
  .textOverlay a:first-of-type {
    /* margin-right: 20px; */
  }
}
@media (min-width: 540px) {
  .links {
    margin-top: 50px;
  }
  .customArrow {
    font-size: 30px;
  }
  .leftArrow {
    left: 20px;
  }
  .rightArrow {
    right: 20px;
  }
}
@media (min-width: 650px) {  
  .slide {
    height: 80vh;
  }
  .textOverlay h1 {
    font-size: 65px;
  }
  .textOverlay p {
    font-size: 25px;
  }
  .textOverlay button {
    font-size: 18px;
    padding: 10px 20px;
    width: 220px;
  }
}
@media (min-width: 992px) {
  .customArrow {
    font-size: 40px;
  }
  .slide {
    height: 80vh;
  }
}
@media (min-width: 1050px) {
  .textOverlay p br {
    display: block;
  }
}
