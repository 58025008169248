/* * {
  font-family: "Lucida Sans";
} */


.carousel {
  /* border: 1px solid red; */
  max-height: 80vh;
  display: flex;   
}
.slide {  
  height: 100%;
  /* width: 95vw; */
  margin-right: 3px;
  margin-left: 3px;  
}

.image {
  /* object-fit: contain;     */
  object-fit: cover;
  height: 100%;
  height: 50vh;
  /* border: 4px solid peru;   */
}
.centerImage {
  transition: filter 0.3s ease-in-out;  
}
.test {
  border: 3px solid red;
}

.centerImage:hover {
  filter: brightness(110%);  
  cursor: pointer;
}
.fullScreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 9999;   
}
.fullScreenImage {
  margin: auto;
  width: 90%;
  height: 70%;
  background: black;  
  z-index: 9999;
  object-fit: cover;  
  /* border: 10px solid red;   */
}


.leftArrow {
  position: absolute;
  top: 50%;
  left: 3vw;
  transform: translateY(-50%);
}

.rightArrow {
  position: absolute;
  top: 50%;
  right: 3vw;
  transform: translateY(-50%);
}

.customArrow {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
  z-index: 2;
}
@media (min-width: 540px) {
  .customArrow {
    font-size: 30px;
  }
  /* .leftArrow {
    left: 20px;
  }
  .rightArrow {
    right: 20px;
  } */
}
@media (min-width: 992px) {
  .customArrow {
    font-size: 40px;
  }
  .fullScreenImage {
    height: 90%;
  }
}
.image {
  /* object-fit: contain;     */
}

/* .leftArrow {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.rightArrow {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
} */

.customArrow {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
  z-index: 2;
}
@media (min-width: 540px) {
  .customArrow {
    font-size: 30px;
  }
  /* .leftArrow {
    left: 20px;
  }
  .rightArrow {
    right: 20px;
  } */
}
@media (min-width: 700px) {
  .slide {  
    margin-right: 20px;  
    height: 50vh;  
    width: auto;
  }  
}
@media (min-width: 992px) {
  .customArrow {
    font-size: 40px;
  }
  .slide {
    height: 80vh;
  }
  .image {
    height: 100%;
  }
}