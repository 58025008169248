@import url('https://fonts.googleapis.com/css2?family=Carlito&display=swap');
@import url('https://fonts.cdnfonts.com/css/leelawadee');


.header * {
  font-family: "Lucida Sans";
}
.content * {
  font-family: "Leelawadee";
}

.header {
  height: 60vh;
  max-width: 1700px;
  background-size: cover;
  background-position: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: multiply;
}
.header h1 {
  margin: auto;
  font-size: 40px;
  color: white;
}
.content {
  font-size: 18px;
  color: rgb(128, 128, 128);
  margin-top: 60px;
  max-width: 1015px;
}

@media (min-width: 650px) {  
  .header h1 {
    font-size: 65px;
  }
}

@keyframes shimmerAnimation {
  0% {
    background-position: -2000px 0;
  }
  25% {
    background-position: -1000px 0;
  }
  50% {
    background-position: 1000px 0;
  }
  75% {
    background-position: 2000px 0;
  }
  100% {
    background-position: -2000px 0;
  }
}

.placeholder {
  flex-grow: 1;
  height: 60vh;
  object-fit: cover;
  position: relative;
  /* background-blend-mode: multiply; */
  /* filter: brightness(60%); */
}

.placeholder::before {
  animation: shimmerAnimation 15s infinite;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #dedede 25%,
    #bcbcbc 50%,
    #cccccc 75%,
    #ffffff 100%
  ); 
}
@media (min-width: 1000px) {
  .placeholder::before {
    animation: shimmerAnimation 10s infinite;
  }
}
@media (min-width: 1500px) {
  .placeholder::before {
    animation: shimmerAnimation 5s infinite;
  }
}