
.wrapper {
  height: 100vh;
  width: 100vw;
}
.image {
  width: 500px;
  height: 500px
}


.step {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  /* background-color: peachpuff; */
}
.step img {
  height: 100%;
  width: 100%;
}
.step .stepContent {
  display: flex;
  flex-direction: column;
  align-items: center;  
  margin-top: 40px;
}
.step .stepContent br {
  display: none;
}
.step .stepContent h1 {
  font-size: 60px;
  color: rgb(214, 214, 214);
  display: inline-block;
  margin: 0;
}
.step .stepContent h2 {
  margin-top: auto;
  display: inline-block;
  font-size: 16px;
  /* margin-left: 20px; */
  margin-bottom: 25px;
  color: rgb(128, 128, 128);
  text-align: center;
}