@import url('https://fonts.googleapis.com/css2?family=Carlito&display=swap');
@import url('https://fonts.cdnfonts.com/css/leelawadee');


.header * {
  font-family: "Lucida Sans";
}
.content *, .main * {
  font-family: "Leelawadee";
}

.header {
  height: 60vh;
  max-width: 1700px;
  background-size: cover;
  background-position: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: multiply;
}
.header h1 {
  text-align: center;
  margin: auto;
  font-size: 40px;
  color: white;
}
.content {
  font-size: 18px;
  color: rgb(128, 128, 128);
  margin-top: 60px;
  max-width: 1015px;
}

.main {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}
.main h1 {
  font-size: 25px;
  color: rgb(128, 128, 128);
  margin: auto;
}

.steps {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  gap: 40px;
}
.step {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  /* background-color: peachpuff; */
}
.step img {
  height: 100%;
  width: 100%;
}
.stepContentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.step .stepContent {
  display: flex;
  flex-direction: column;
  align-items: center;  
  margin-top: 40px;
}
.step .stepContent br {
  display: none;
}
.step .stepContent h1 {
  font-size: 60px;
  color: rgb(214, 214, 214);
  display: inline-block;
  margin: 0;
}
.step .stepContent h2 {
  margin-top: auto;
  display: inline-block;
  font-size: 16px;
  /* margin-left: 20px; */
  margin-bottom: 25px;
  color: rgb(128, 128, 128);
  text-align: center;
}
@keyframes shimmerAnimation {
  0% {
    background-position: -2000px 0;
  }
  25% {
    background-position: -1000px 0;
  }
  50% {
    background-position: 1000px 0;
  }
  75% {
    background-position: 2000px 0;
  }
  100% {
    background-position: -2000px 0;
  }
}

.placeholder {
  flex-grow: 1;
  height: 60vh;
  object-fit: cover;
  position: relative;
  /* background-blend-mode: multiply; */
  /* filter: brightness(60%); */
}

.placeholder::before {
  animation: shimmerAnimation 15s infinite;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #dedede 25%,
    #bcbcbc 50%,
    #cccccc 75%,
    #ffffff 100%
  ); 
}

.rendered {
  display: flex;
}
.loading {
  visibility: hidden;
}
.ring {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}


@media (min-width: 450px) {
  .step .stepContent {
    flex-direction: row;
  }
  .step .stepContent h1 {
    font-size: 100px;
  }
  .step .stepContent h2 {
    text-align: left;
    font-size: 18px;
    margin-left: 20px;    
  }
}
@media (min-width: 650px) {  
  .header h1 {
    font-size: 65px;
  }
  .steps {
    gap: 70px;
  }
  .step .stepContent {
    margin-top: 70px;
  }
  .step .stepContent h2 {
    margin-bottom: 28px;
  }
}
@media (min-width: 1000px) {
  .placeholder::before {
    animation: shimmerAnimation 10s infinite;
  }
  .main {
    margin-top: 100px;
  }
  .steps {
    margin-top: 100px;
    gap: 150px;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .step {
    flex-direction: row;
    max-width: none;
    /* max-width: 1000px; */
    flex-grow: 0;    
  }
  .main h1 {
    font-size: 40px;
  }
  .step img {
    width: 50%;
    /* max-width: 700px; */
    /* max-width: 500px; */
  }
  .step {
    position: relative;
  }
  .step .stepContent {
    position: absolute;
    /* top: 0px; */
    margin-top: 0;
  }
  .step.special .stepContent:first-of-type{
    position: absolute;
    /* top: 50%; */
    transform: translateY(-60%);
  }
  .step.special .stepContent:last-of-type{
    position: absolute;
    /* top: 50%; */
    transform: translateY(240%);
  }
  .step .stepContent {
    width: 50%;
    margin-left: auto;
    padding-left: 30px;
    padding-right: 30px;
    /* position: relative;     */
    right: 0px;
    /* top: -310px; */
    height: fit-content;
  }
}

@media (min-width: 1200px) {
  .step {
    max-width: none;
    max-width: 1400px;
  }
  .step img {
    /* width: 50%; */
    max-width: 600px;
    /* max-width: 500px; */
  }
  .step .stepContent h1 {
    font-size: 130px;
  }
  .step .stepContent h2 {
    font-size: 20px;
  }
  .step.special .stepContent:first-of-type{
    position: absolute;
    /* top: 50%; */
    transform: translateY(-50%);
  }
  .step.special .stepContent:last-of-type{
    position: absolute;
    /* top: 50%; */
    transform: translateY(200%);
  }
  .step .stepContent h2 {
    margin-bottom: 32px;
  }
}
@media (min-width: 1500px) {
  .placeholder::before {
    animation: shimmerAnimation 5s infinite;
  }
  .step {
    max-width: 1700px;
  }
  .step img {
    /* width: 50%; */
    max-width: 700px;
    /* max-width: 500px; */
  }
  .step .stepContent h1 {
    font-size: 200px;
  }
  .step .stepContent h2 {
    font-size: 23px;
    padding-bottom: 22px;
  }
  .step.special .stepContent:first-of-type{
    position: absolute;
    /* top: 50%; */
    transform: translateY(-50%);
  }
  .step.special .stepContent:last-of-type{
    position: absolute;
    /* top: 50%; */
    transform: translateY(150%);
  }
}
@media (min-width: 1700px) {
  .step .stepContent h2 {
    font-size: 28px;
    margin-left: 60px;
  }
}
