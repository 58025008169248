@import url('https://fonts.cdnfonts.com/css/leelawadee');

.wrapper * {
  font-family: "Leelawadee";
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
/* .content img {
  width: 100%;  
  margin-bottom: 50px;
  max-width: 500px;
} */
.content .text {  
  margin-bottom: 50px;
  text-align: center;
}
.content .text h1 {
  font-weight: 800;
  font-size: 60px;
}
.content .text h1:first-of-type {
  color: #2F4663;
}
.content .text h1:last-of-type { 
  color: #528EAF;
}

.content .property {
  margin-top: 20px;
}
.content .property label {
  color: rgb(128, 128, 128);
}
.content .property a {  
  display: block;
  text-decoration: none;
  color: black;
}

.content {
  max-width: 500px;
}
.content:last-of-type {
  margin-top: 75px;
  margin-bottom: 50px;
  width: 100%;
}
.content:last-of-type h2 {
  font-size: 40px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;
}
.content:last-of-type form {
  margin-top: 20px;  
}
.content:last-of-type form input {
  border: none;
  border-bottom: 1px solid rgb(112, 110, 110);
  border-radius: 0;
  padding: 10px 5px;
}
.content:last-of-type form button {
  margin-top: 15px;
  width: 100%;
  padding: 10px 0;
  border-radius: 0;
  background-color: black;
}

@media (min-width: 500px) {
  .content {
    /* width: 100%; */
  }
  .content .text {
    text-align: start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;    
  }
  .content .text h1:first-of-type {
    display: inline-block;
  }
  .content .text h1:last-of-type { 
    display: inline-block;
    margin-left: 120px;
  }
}
@media (min-width: 1100px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;        
    max-width: 1000px;
    height: 60vh;
    margin-top: 200px;
  }
  .wrapper .content {
    margin-top: 0;
    margin-bottom: auto;
  }
  .content:first-of-type {
    margin-top: -10px;
  }
  .content:first-of-type * {
    font-size: 20px;
  }
  .content:last-of-type {
    max-width: 350px;
    margin-top: 0px;
  }
  .content:last-of-type h2 {
    font-size: 60px;
    margin-bottom: 50px;
  }
  .content:last-of-type form * {
    font-size: 20px;
  }
  .content .text h1:first-of-type {
    font-size: 80px;
  }
  .content .text h1:last-of-type { 
    font-size: 80px;
  }
  
}
