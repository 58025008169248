@import url('https://fonts.cdnfonts.com/css/leelawadee');

.link {
  font-family: "Leelawadee";
}

.wrapper {
  background-color: transparent;
  /* max-width: 1700px; */
  margin: auto;
  padding-bottom: 20px;  
  border: none;
}
.bg-color {
  background-color: white;
  transition: background 0.5s ease;
}

.bg-color.fade-out {  
  background-color: transparent;
}


.logo {
  height: 100px;
  width: 200px;
  margin-left: -18px;
  /* border: 1px solid red; */
}
.navCollapse {
  margin-top: auto;
}
.nav {
  gap: 40px;
}
.link {
  font-size: 20px;
  text-decoration: none;
  color: rgb(128, 128, 128);
  border: none;
}
.link:hover {
  text-decoration: underline;
  color: rgb(84, 83, 83);
}
.link:last-of-type:hover {
  border-bottom: none;
}
.toggle-btn {
  margin-top: auto;
}

.active {
  /* border-bottom: 1px solid rgb(44, 44, 44); */
  text-decoration: underline;
  color: rgb(44, 44, 44);
}
.container {
  width: 95vw;
  padding: 0;
  max-width: none;
}

@media (max-width: 992px) {
  .container {
    padding: 0;
  }
  .link {
    width: 100%;
    border-bottom: 1px solid grey;
    text-align: center;
    padding-bottom: 20px;
  }
  .link:last-of-type {
    border-bottom: none;
  }
  .nav {
    margin-top: 30px;
  }
  .icon {
    height: 40px;
  }
}

@media (min-width: 992px) {
  .icon {
    height: 25px;
  }
  .container {
    /* max-width: 1700px; */
  }
}
